import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem, CModalFooter } from '@coreui/react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import Button, { Types, Variants } from '../../../buttons/Button';
import OrderFulfillmentsManager from '../../../../services/api/OrderFulfillmentsManager';
import { DeliveryProvider, ORDER_METHODS } from '../../../../services/exports/Constants';
import useHelpers from '../../../../hooks/useHelpers';

export default function OrderTimeChange({ order, updatePickupTime, onError }) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Modals:OrderDetails:Footers:OrderTimeChange' });

  const { company } = useSelector((state) => state.company);

  const { formatTime } = useHelpers();

  const [preparationTime, setPreparationTime] = useState(order.estimated_preparation_time);
  const [loading, setLoading] = useState(false);

  const step = company?.order_approval_time_step ?? 10;
  const isInitialStep = preparationTime - step <= 0;

  const currentPickupTime = moment(order.estimated_pickup_time);
  const newPickupTime = moment(order.paid_at)
    .add(preparationTime + 1, 'minutes')
    .startOf('minute');

  const minusButtonDisabled =
    isInitialStep ||
    (order.scheduled_for && preparationTime <= order.estimated_preparation_time) ||
    (order.method === ORDER_METHODS.delivery &&
      order.delivery_provider !== DeliveryProvider.Standalone &&
      preparationTime === order.estimated_preparation_time);

  async function confirm() {
    setLoading(true);
    OrderFulfillmentsManager.updatePreparationTime(
      order.fulfillment_id,
      { estimated_preparation_time: preparationTime },
      true,
      {
        currentPickupTime: currentPickupTime.format('HH:mm'),
        newPickupTime: newPickupTime.format('HH:mm'),
      },
      (data) => {
        setLoading(false);
        updatePickupTime(data?.data);
      },
      (data) => {
        setLoading(false);
        onError(data?.message);
      },
      () => setLoading(false),
    );
  }

  return (
    <CModalFooter>
      {order?.method === ORDER_METHODS.delivery && order?.delivery_provider !== DeliveryProvider.Standalone ? (
        <div className="website-text w-100 text-center py-3">
          <Trans
            t={t}
            values={{
              time: formatTime(order?.delivery?.pickup_estimate),
            }}
          >
            text.delivery.first_delivery
          </Trans>
        </div>
      ) : (
        <CAccordion className="w-100" flush>
          <CAccordionItem>
            <CAccordionHeader>
              <div className="website-text bold text-center">{t('header')}</div>
            </CAccordionHeader>
            <CAccordionBody>
              <div className="website-text w-100 text-center mt-3 sm-mt-2">
                <Trans
                  t={t}
                  values={{
                    date: currentPickupTime.isSame(moment(), 'day')
                      ? t('labels.today')
                      : currentPickupTime.format('dddd, DD MMM'),
                    time: formatTime(currentPickupTime),
                  }}
                >
                  {order.method === ORDER_METHODS.delivery
                    ? `text.${order.method}.${order.delivery_provider}`
                    : `text.${order.method}`}
                </Trans>
              </div>
              <div className="tw-grid tw-grid-cols-3 tw-gap-4 mt-3 sm-mt-2">
                <div>
                  <Button
                    title={
                      <>
                        <b>-</b>
                        <br />
                        {step} min
                      </>
                    }
                    disabled={minusButtonDisabled}
                    onClick={() => setPreparationTime((current) => current - step)}
                    className="w-100 h-100"
                    type={Types.primary}
                    variant={Variants.outline}
                  />
                </div>
                <div>
                  <div className="website-text bold text-center">
                    {order.method === ORDER_METHODS.delivery ? t('labels.delivery_time') : t('labels.pickup_time')}
                  </div>
                  <div className="website-text bold text-center mt-1">
                    {newPickupTime.isSame(moment(), 'day') ? t('labels.today') : newPickupTime.format('dddd, DD MMM')}
                  </div>
                  <div className="website-text bold text-center mt-1">{formatTime(newPickupTime)}</div>
                </div>
                <div>
                  <Button
                    title={
                      <>
                        <b>+</b>
                        <br />
                        {step} min
                      </>
                    }
                    onClick={() => setPreparationTime((current) => current + step)}
                    className="w-100 h-100"
                    type={Types.primary}
                    variant={Variants.outline}
                  />
                </div>
              </div>
            </CAccordionBody>
          </CAccordionItem>
          <Button
            title={
              <div className="website-text bold white p-1">
                {order.method === ORDER_METHODS.delivery ? t('buttons.confirm_delivery') : t('buttons.confirm_pickup')}
              </div>
            }
            onClick={confirm}
            className="w-100 mt-4 sm-mt-3"
            disabled={newPickupTime.isSame(currentPickupTime)}
            loading={loading}
          />
        </CAccordion>
      )}
    </CModalFooter>
  );
}
