import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function LanguageSwitcher() {
  const { i18n, t } = useTranslation();

  const { company } = useSelector((state) => state.company);

  function switchLanguage() {
    if (i18n.language === 'en') {
      return i18n.changeLanguage('de');
    }

    return i18n.changeLanguage('en');
  }

  return (
    company?.locales?.length > 1 && (
      <li className="nav-item">
        <a className="nav-link" href="#" onClick={switchLanguage}>
          {t('Nav:switch_language.title')}
        </a>
      </li>
    )
  );
}

export default LanguageSwitcher;
