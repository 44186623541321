import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment-timezone';
import en from '../../resources/lang/en';
import de from '../../resources/lang/de';
import LocalizationManager from './LocalizationManager';
import 'moment/dist/locale/de';
import 'moment/dist/locale/en-gb';

const DEFAULT_LANGUAGE = 'en';

const languageDetector = {
  type: 'languageDetector',
  // If this is set to true, your detect function receives a callback function that you should call with your language,
  // useful to retrieve your language stored in AsyncStorage for example
  async: true,

  init: (_services, _detectorOptions, _i18nextOptions) => {
    /* use services and options */
  },
  detect: (callback) => {
    const languageFromUrl = LocalizationManager.getLanguageFromUrl();

    if (languageFromUrl) {
      return callback(languageFromUrl);
    }

    const browserLanguage = LocalizationManager.getLanguage();

    if (!browserLanguage) {
      const preferredLanguage = LocalizationManager.getPreferredLanguage();

      if (!preferredLanguage) {
        return callback(DEFAULT_LANGUAGE);
      }

      return callback(preferredLanguage);
    }

    return callback(browserLanguage);
  },
  cacheUserLanguage: (lng) => {
    // Cache language if necessary
    LocalizationManager.saveLanguage(lng);
    moment.locale(lng);
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      de,
      en,
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });
