import {
  ORDER_METHODS,
  PAYMENT_METHODS,
  REFUND_REASONS,
  ROOM_SERVICE_LOCATION_TYPES,
} from '../../../../../../services/exports/Constants';

export default {
  header: 'Order Information',
  sections: {
    driver_info: 'Driver info',
    order_info: 'Order Information',
    price_info: 'Price Information',
    refunds: 'Refunds',
  },
  order_methods: {
    [ORDER_METHODS.pickup]: 'Pickup',
    [ORDER_METHODS.delivery]: 'Delivery',
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Room service',
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Table service',
  },
  room_service_location_types: {
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: {
      details_label: 'Room number',
    },
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: {
      details_label: 'Table number',
    },
  },
  labels: {
    wolt_support: 'Wolt support phone number: {{phone_number}}',
    wolt_support_text:
      'If you have any questions about the order, the driver or problems, call Wolt. It is important to say that it is a <strong>Wolt Drive</strong> order',
    dummy_order: 'Test order',
    asap: 'ASAP',
    driver_name: 'Driver name',
    driver_phone_number: 'Driver phone',
    original: 'Original',
    delivery_fee: 'Delivery fee',
    tip: 'Tip',
    service_fee: 'Online processing',
    payment_method_upcharge_fee: {
      [PAYMENT_METHODS.PAYPAL]: 'PayPal fee',
    },
    promo_applied: 'Promo applied',
    tax: 'Taxes',
    total: 'Total',
    online_payment: 'Customer already paid in FoodAmigos app',
    cash_payment: 'Cash payment',
    old_order: 'This order has been successfully fulfilled',
    pickup_time: 'Pickup time',
    pickup_asap: 'The customer has asked for the fastest possible pickup.',
    points_used: 'This order includes a loyalty point reward redeemed by a loyal customer',
    order_instructions: 'Order instructions:',
    today: 'today',
  },
  schedule: {
    header: 'Scheduled for {{date}}, {{time}}',
    text: 'We will remind you when it’s time to start preparing the order',
  },
  refund_reasons: {
    [REFUND_REASONS.RequestedByCustomer]: 'Requested by customer',
    [REFUND_REASONS.Reclamation]: 'Reclamation',
    [REFUND_REASONS.CommunicationError]: 'Communication error',
    [REFUND_REASONS.Other]: 'Other',
    null: '',
  },
  toasts: {
    print_one: 'Printing {{count}} receipt: Order <strong>#{{orderNumber}}</strong>...',
    print_other: 'Printing {{count}} receipts: Order <strong>#{{orderNumber}}</strong>...',
    refund_successful: 'Refund successful: Order <strong>#{{orderNumber}}</strong>...',
  },
  buttons: {
    print: 'Print',
    confirm: 'Confirm',
    reject: 'Reject',
    refund: 'Refund',
  },
};
