import React from 'react';

export default function DeliveryIcon({ className, color }) {
  return (
    <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" className={className ?? 'h-10 w-10'}>
      <path
        d="M35.2857 17.8571C35.2857 16.2857 34 15 32.4286 15H28.1429V17.8571H32.4286V21.6429L27.4571 27.8571H22.4286V20.7143H16.7143C13.5571 20.7143 11 23.2714 11 26.4286V30.7143H13.8571C13.8571 33.0857 15.7714 35 18.1429 35C20.5143 35 22.4286 33.0857 22.4286 30.7143H28.8286L35.2857 22.6429V17.8571ZM18.1429 32.1429C17.3571 32.1429 16.7143 31.5 16.7143 30.7143H19.5714C19.5714 31.5 18.9286 32.1429 18.1429 32.1429Z"
        fill={color ?? 'white'}
      />
      <path d="M22.4319 16.4277H15.2891V19.2849H22.4319V16.4277Z" fill={color ?? 'white'} />
      <path
        d="M35.2857 26.4277C32.9143 26.4277 31 28.342 31 30.7134C31 33.0849 32.9143 34.9992 35.2857 34.9992C37.6571 34.9992 39.5714 33.0849 39.5714 30.7134C39.5714 28.342 37.6571 26.4277 35.2857 26.4277ZM35.2857 32.142C34.5 32.142 33.8571 31.4992 33.8571 30.7134C33.8571 29.9277 34.5 29.2849 35.2857 29.2849C36.0714 29.2849 36.7143 29.9277 36.7143 30.7134C36.7143 31.4992 36.0714 32.142 35.2857 32.142Z"
        fill={color ?? 'white'}
      />
    </svg>
  );
}
