import React from 'react';
import classnames from 'classnames';

export default function PhoneIcon({ className, color }) {
  return (
    <svg
      className={classnames('h-6 w-6', className)}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12" r="12" fill="#69CE8C" />
      <path
        d="M18.1131 18.3524C16.6426 18.3524 15.1896 18.0318 13.7543 17.3906C12.319 16.7494 11.0131 15.8406 9.83667 14.6641C8.6602 13.4877 7.75138 12.1818 7.1102 10.7465C6.46903 9.31119 6.14844 7.85825 6.14844 6.38766C6.14844 6.1759 6.21903 5.99943 6.3602 5.85825C6.50138 5.71707 6.67785 5.64648 6.88961 5.64648H9.74844C9.91314 5.64648 10.0602 5.70237 10.1896 5.81413C10.319 5.9259 10.3955 6.05825 10.419 6.21119L10.8778 8.68178C10.9014 8.87001 10.8955 9.02884 10.8602 9.15825C10.8249 9.28766 10.7602 9.39943 10.6661 9.49354L8.95432 11.223C9.18961 11.6582 9.46903 12.0788 9.79255 12.4847C10.1161 12.8906 10.472 13.2818 10.8602 13.6582C11.2249 14.023 11.6073 14.3612 12.0073 14.673C12.4073 14.9847 12.8308 15.27 13.2778 15.5288L14.9367 13.87C15.0426 13.7641 15.1808 13.6847 15.3514 13.6318C15.522 13.5788 15.6896 13.5641 15.8543 13.5877L18.2896 14.0818C18.4543 14.1288 18.5896 14.2141 18.6955 14.3377C18.8014 14.4612 18.8543 14.5994 18.8543 14.7524V17.6112C18.8543 17.823 18.7837 17.9994 18.6426 18.1406C18.5014 18.2818 18.3249 18.3524 18.1131 18.3524ZM8.28373 9.88178L9.44844 8.71707L9.14844 7.05825H7.57785C7.63667 7.5406 7.71903 8.01707 7.82491 8.48766C7.93079 8.95825 8.08373 9.42295 8.28373 9.88178ZM14.6014 16.1994C15.0602 16.3994 15.5278 16.5582 16.0043 16.6759C16.4808 16.7935 16.9602 16.87 17.4426 16.9053V15.3524L15.7837 15.0171L14.6014 16.1994Z"
        fill={color ?? 'white'}
      />
    </svg>
  );
}
