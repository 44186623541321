import * as React from 'react';

function RefreshIcon({ color, className, style }) {
  return (
    <svg
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? 'h-8 w-8'}
      style={style}
    >
      <path
        d="M13.9902 22.7422C18.9736 22.7422 23.0869 18.6289 23.0869 13.6543C23.0869 8.67969 18.9648 4.56641 13.9814 4.56641C9.00684 4.56641 4.90234 8.67969 4.90234 13.6543C4.90234 18.6289 9.01562 22.7422 13.9902 22.7422ZM8.38281 13.3203V12.8809C8.38281 11.3604 9.47266 10.3848 11.1777 10.3848H14.667V9.48828C14.667 9.13672 14.8955 8.9082 15.2559 8.9082C15.3965 8.9082 15.5635 8.96973 15.6777 9.05762L17.585 10.666C17.8838 10.9033 17.8838 11.29 17.585 11.5361L15.6689 13.1357C15.5459 13.2324 15.3965 13.2939 15.2559 13.2939C14.8955 13.2939 14.667 13.0566 14.667 12.7139V11.7734H11.0811C10.3076 11.7734 9.80664 12.248 9.80664 12.9863V13.3203C9.80664 13.707 9.49023 14.0146 9.09473 14.0146C8.69922 14.0146 8.38281 13.707 8.38281 13.3203ZM10.3955 16.6953C10.0967 16.458 10.0967 16.0625 10.3955 15.8252L12.3115 14.2256C12.4346 14.1289 12.584 14.0674 12.7246 14.0674C13.0938 14.0674 13.3223 14.3047 13.3223 14.6475V15.5791H16.8994C17.6816 15.5791 18.1738 15.1045 18.1738 14.375V14.0322C18.1738 13.6455 18.4902 13.3379 18.8857 13.3379C19.2812 13.3379 19.6064 13.6455 19.6064 14.0322V14.4717C19.6064 15.9922 18.5166 16.9766 16.8115 16.9766H13.3223V17.873C13.3223 18.2246 13.0938 18.4531 12.7246 18.4531C12.584 18.4531 12.417 18.3916 12.3115 18.3037L10.3955 16.6953Z"
        fill={color ?? 'black'}
      />
    </svg>
  );
}

export default RefreshIcon;
