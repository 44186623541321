import React from 'react';
import { useSelector } from 'react-redux';
import { CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react';
import SimpleBar from 'simplebar-react';
import { cilPhone } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import tw from 'twin.macro';
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';
import { SidebarNav } from './SidebarNav';
import 'simplebar/dist/simplebar.min.css';
import { actionCreators } from '../store/actions';
import getNavItems from '../_nav';
import ReduxHooks from '../store/ReduxHooks';
import LanguageSwitcher from './LanguageSwitcher';
import { ROUTES } from '../services/exports/Constants';

function Sidebar() {
  const { company } = useSelector((state) => state.company);
  const { sidebar } = useSelector((state) => state);
  const { service_provider } = company;
  const { show } = sidebar;

  return (
    <CSidebar
      position="fixed"
      unfoldable={false}
      visible={show}
      onVisibleChange={(visible) => {
        ReduxHooks.dispatch(actionCreators.sidebar.toggle(visible));
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <h3 className="sidebar-brand-full">{company?.name ?? null}</h3>
        <h3 className="sidebar-brand-narrow">{company?.name ?? null}</h3>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <SidebarNav items={getNavItems(company)} />
          <LanguageSwitcher />
        </SimpleBar>
      </CSidebarNav>
      <div className="d-flex flex-column mb-3 ms-3">
        <PhoneNumber>
          <CIcon icon={cilPhone} className="me-1" />
          Tel-Support: {service_provider?.support_phone_number}
        </PhoneNumber>
      </div>
      <div className="nav-item p-3">
        <NavLink className="nav-link" to={ROUTES.logout}>
          {i18next.t('Nav:logout.title')}
        </NavLink>
      </div>
    </CSidebar>
  );
}

export default Sidebar;

const PhoneNumber = tw.div`font-bold text-primary`;
