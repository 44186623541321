import React from 'react';

export default function PickupIcon({ className, color }) {
  return (
    <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" className={className ?? 'h-10 w-10'}>
      <path
        d="M24.9595 17.5997C26.2955 17.5997 27.3803 16.4948 27.3803 15.2091C27.3803 13.8631 26.2955 12.7783 24.9595 12.7783C23.6236 12.7783 22.5689 13.8631 22.5689 15.2091C22.5689 16.4948 23.6236 17.5997 24.9595 17.5997ZM28.3848 36.7348C28.9573 36.7348 29.4696 36.3129 29.4696 35.6098C29.4696 35.3486 29.3993 35.1076 29.2586 34.8263L27.1091 30.4669C26.9182 30.0752 26.6973 29.7236 26.4964 29.4424L25.1805 27.5741L25.2609 27.323C25.6225 26.1678 25.7631 25.4647 25.8435 24.3598L26.0645 21.256C26.1749 19.7292 25.291 18.4837 23.6939 18.4837C22.5287 18.4837 21.685 19.1165 20.6002 20.1712L18.9528 21.8085C18.3903 22.3609 18.2095 22.8129 18.1593 23.516L17.9484 26.0674C17.8982 26.7605 18.2899 27.2125 18.9127 27.2527C19.5053 27.2928 19.9473 26.9413 20.0176 26.198L20.2486 23.4056L21.2229 22.5417C21.4037 22.381 21.6649 22.4714 21.6448 22.7426L21.464 25.2538C21.3736 26.4692 21.906 27.3531 22.5086 28.1064L24.6582 30.8085C24.8691 31.0696 24.8993 31.1701 24.9997 31.3609L27.2899 36.0116C27.5611 36.554 27.9629 36.7348 28.3848 36.7348ZM28.8569 24.8721H31.2877C31.9908 24.8721 32.4328 24.4602 32.4328 23.8375C32.4328 23.2147 31.9807 22.8029 31.2776 22.8029H28.7363L26.9685 20.8843L26.7776 23.6466L27.531 24.4C27.8926 24.7616 28.2341 24.8721 28.8569 24.8721ZM18.6415 36.7348C18.993 36.7348 19.3044 36.5741 19.5254 36.3129L22.7598 32.4658C23.0912 32.064 23.1314 31.9636 23.272 31.5718C23.3524 31.3107 23.4227 31.0596 23.483 30.8185L21.6348 28.5082L21.0924 31.0294L18.0287 34.6656C17.7475 34.987 17.5566 35.2683 17.5566 35.6701C17.5566 36.2928 18.0287 36.7348 18.6415 36.7348Z"
        fill={color ?? 'white'}
      />
    </svg>
  );
}
