import * as React from 'react';
import classnames from 'classnames';

export default function CheckmarkIcon({ className, color }) {
  return (
    <svg viewBox="0 0 102 82" fill="none" xmlns="http://www.w3.org/2000/svg" className={classnames('w-6', className)}>
      <rect
        x="91.8984"
        y="7.72461"
        width="16.9853"
        height="86.7"
        rx="8.49264"
        transform="rotate(45 91.8984 7.72461)"
        fill={color ?? '#69CE8C'}
      />
      <rect
        width="16.9853"
        height="57.75"
        rx="8.49265"
        transform="matrix(-0.707719 0.706494 -0.707719 -0.706494 52.8945 68.8604)"
        fill={color ?? '#69CE8C'}
      />
    </svg>
  );
}
