import React from 'react';
import tw, { styled } from 'twin.macro';
import collect from 'collect.js';
import { useTranslation } from 'react-i18next';
import useStore from '../../hooks/useStore';
import { INNER_PRINTER_RECEIPT_ELEMENT_ID, THERMAL_PRINTER_SIZES } from '../../services/exports/Constants';
import useHelpers from '../../hooks/useHelpers';

export default function InnerPrinterOrderHistoryReceipt(props) {
  const { payload } = props;

  const { range, stats } = payload;

  const { t: translate } = useTranslation(null, { keyPrefix: 'Components:Print:InnerPrinterOrderHistoryReceipt' });

  const company = useStore()?.company?.company;

  const lng = company?.legal_lang ?? 'de';

  const { formatCurrency, formatDateTime } = useHelpers();

  const pageWidth =
    window.innerWidth < THERMAL_PRINTER_SIZES.FIFTY_EIGHT_MM
      ? window.innerWidth * (window.innerWidth / THERMAL_PRINTER_SIZES.FIFTY_EIGHT_MM)
      : THERMAL_PRINTER_SIZES.FIFTY_EIGHT_MM;

  function t(key) {
    return translate(key, { lng });
  }

  const renderTotalOrders = () => (
    <Section>
      <H3>
        {t('total_orders.title')} {formatDateTime(collect(range).get(0), { format: 'DD.MM.YYYY' }, lng)}
        {' - '}
        {formatDateTime(collect(range).get(1), { format: 'DD.MM.YYYY' }, lng)}
      </H3>
      <Paragraph>
        {t('total_orders.orders')} {stats?.orders_count ?? 0}
      </Paragraph>
      <Paragraph>
        {t('total_orders.sales_volume')} {formatCurrency(stats?.sales ?? 0)}
      </Paragraph>
      <Paragraph>
        {t('total_orders.delivery_costs')} {formatCurrency(stats?.delivery_fees ?? 0)}
      </Paragraph>
    </Section>
  );

  const renderCashOrders = () => (
    <Section>
      <H3>{t('cash_orders.title')}</H3>
      <Paragraph>
        {t('cash_orders.orders')} {stats?.cash_orders_count ?? 0}
      </Paragraph>
      <Paragraph>
        {t('cash_orders.sales_volume')} {formatCurrency(stats?.cash_sales ?? 0)}
      </Paragraph>
      <Paragraph>
        {t('cash_orders.delivery_costs')} {formatCurrency(stats?.cash_delivery_fees ?? 0)}
      </Paragraph>
    </Section>
  );

  const renderOnlineOrders = () => (
    <Section>
      <H3>{t('online_orders.title')}</H3>
      <Paragraph>
        {t('online_orders.orders')} {stats?.online_orders_count ?? 0}
      </Paragraph>
      <Paragraph>
        {t('online_orders.sales_volume')} {formatCurrency(stats?.online_sales ?? 0)}
      </Paragraph>
      <Paragraph>
        {t('online_orders.delivery_costs')} {formatCurrency(stats?.online_delivery_fees ?? 0)}
      </Paragraph>
    </Section>
  );

  return (
    <Container
      id={INNER_PRINTER_RECEIPT_ELEMENT_ID}
      style={{
        zIndex: 0,
        width: pageWidth,
        left: -pageWidth,
      }}
    >
      <Paragraph>{t('total')}</Paragraph>
      <Paragraph>{company?.name}</Paragraph>
      {renderTotalOrders()}
      {renderCashOrders()}
      {renderOnlineOrders()}
    </Container>
  );
}

const Container = tw.div`bottom-0 fixed pt-[50px] pb-[130px]`;

const Section = styled.div(({ borderTop, borderBottom }) => [
  tw`py-4 border-black border-solid border-[5px] border-l-0 border-r-0`,
  !borderTop && tw`border-t-0`,
  !borderBottom && tw`border-b-0`,
]);

const H3 = styled.h3(({ noMargin, center, noUnderline, regular, className }) => [
  tw`mb-2 pb-0 text-black text-[28px]
  font-bold border-l-0 border-r-0 border-t-0 border-solid inline-block`,
  noMargin && tw`mb-0`,
  center && tw`text-center`,
  noUnderline && tw`border-b-0`,
  regular && tw`font-normal`,
  className,
]);

const Paragraph = styled.p(({ noMargin, center, bold, shrink, noShrink, rightPadding, big }) => [
  tw`mb-0 pb-0 text-black text-xl font-medium`,
  noMargin && tw`mb-0`,
  center && tw`text-center`,
  bold && tw`font-bold`,
  shrink && tw`flex-shrink`,
  noShrink && tw`flex-none`,
  rightPadding && tw`pr-2`,
  big && tw`text-[22px]`,
]);
