import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { CBadge } from '@coreui/react';

export function SidebarNav({ items }) {
  const location = useLocation();
  const navLink = (name, icon, badge) => (
    <>
      {icon && icon}
      {name && name}
      {badge && (
        <CBadge color={badge.color} className="ms-auto">
          {badge.text}
        </CBadge>
      )}
    </>
  );

  const navItem = (item, index) => {
    const { component, name, badge, icon, ...rest } = item;

    const Component = component;

    const active = location.pathname === item.to;

    return (
      <Component
        {...(rest.to &&
          !rest.items && {
            component: NavLink,
            activeClassName: active ? 'active' : 'inactive',
          })}
        to={() => (item?.onClick ? item.onClick() : item.to)}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    );
  };

  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item;

    const Component = component;

    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
      </Component>
    );
  };

  return items && items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)));
}
