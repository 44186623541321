import React, { useMemo } from 'react';
import { CModal, CModalFooter, CModalHeader } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import Button from '../../buttons/Button';
import { FULFILLMENT_STATUSES, ORDER_METHODS } from '../../../services/exports/Constants';

export default function CloseOrderModal({
  loading = false,
  showOrderDetailsModal = false,
  toggleModal,
  toggleOrderDetailsModal,
  order,
  onApprove,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Modals:CloseOrder:CloseOrderModal' });

  const title = useMemo(() => {
    if (order.fulfillment_status === FULFILLMENT_STATUSES.rejected) {
      return t(`text.status.${order.fulfillment_status}`);
    }

    return order.method === ORDER_METHODS.delivery
      ? t(`text.${order.method}.${order.delivery_provider}`)
      : t(`text.${order.method}`);
  }, [order.method, order.delivery_provider, order.fulfillment_status]);

  async function closeOrder() {
    onApprove(order);
    closeModal();
  }

  function closeModal() {
    if (showOrderDetailsModal) {
      toggleOrderDetailsModal();
    }

    toggleModal();
  }

  function renderButtons() {
    return (
      <CModalFooter className="justify-content-between border-none bg-white p-4">
        <ButtonsContainer>
          <div onClick={closeModal} className="website-text bold cursor-pointer d-flex">
            <div className="my-auto">{t('buttons.cancel')}</div>
          </div>
          <Button
            title={<div className="website-text bold white">{t('buttons.confirm')}</div>}
            onClick={closeOrder}
            loading={loading}
          />
        </ButtonsContainer>
      </CModalFooter>
    );
  }

  return (
    <CModal visible alignment="center" scrollable size="lg" onClose={() => closeModal()}>
      <CModalHeader closeButton={false} className="border-none p-4">
        <h5 className="tw-w-full tw-text-center">{title}</h5>
      </CModalHeader>
      {renderButtons()}
    </CModal>
  );
}

const ButtonsContainer = tw.div`grid grid grid-cols-2 gap-8 mx-auto`;
