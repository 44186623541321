import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CModalFooter } from '@coreui/react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import OrderFulfillmentsManager from '../../../../services/api/OrderFulfillmentsManager';
import {
  DeliveryMode,
  DeliveryProvider,
  FULFILLMENT_STATUSES,
  ORDER_METHODS,
} from '../../../../services/exports/Constants';
import SingleStepOrderConfirmation from './SingleStepOrderConfirmation';
import MultiStepOrderConfirmation from './MultiStepOrderConfirmation';

export default function OrderConfirmation({ order, acceptOrder, onError, toggleModal, printing }) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Modals:OrderDetails:Footers:OrderConfirmation' });

  const { company } = useSelector((state) => state.company);

  const [preparationTime, setPreparationTime] = useState(order.estimated_preparation_time);
  const [loading, setLoading] = useState(false);

  const pickupTime = moment(order?.scheduled_for ? order.paid_at : undefined)
    .add(preparationTime + 1, 'minutes')
    .startOf('minute');
  const [minutesFromNow, setMinutesFromNow] = useState(pickupTime.diff(pickupTime, 'minute'));
  const isToday = useMemo(() => pickupTime.isSame(moment(), 'day'), [pickupTime]);

  const isOrderAcceptable =
    order.fulfillment_status === FULFILLMENT_STATUSES.reviewed ||
    order.fulfillment_status === FULFILLMENT_STATUSES.accepted;
  const isExternalDelivery =
    order.method === ORDER_METHODS.delivery && order.delivery_provider !== DeliveryProvider.Standalone;

  useEffect(() => {
    const id = setInterval(() => setMinutesFromNow(pickupTime.diff(moment(), 'minute')), 10);

    return () => clearInterval(id);
  }, [pickupTime]);

  function getStatus() {
    if (
      order.fulfillment_status === FULFILLMENT_STATUSES.created ||
      order.fulfillment_status === FULFILLMENT_STATUSES.reviewed
    ) {
      return FULFILLMENT_STATUSES.accepted;
    }

    return order.fulfillment_status;
  }

  async function _acceptOrder() {
    setLoading(true);
    const response = await OrderFulfillmentsManager.update(order.fulfillment_id, {
      status: getStatus(),
      estimated_preparation_time: preparationTime,
      is_manually_confirmed: true,
    });
    const { success, status, data } = response;
    setLoading(false);

    if (success) {
      acceptOrder(data?.data);

      return response;
    }

    const message =
      {
        412: t(`toasts.${data.error_code}`),
      }[status] ?? data?.message;

    onError(message);

    return response;
  }

  const orderMethod = useMemo(() => {
    if (order.method === ORDER_METHODS.room_service) {
      return t(`order_methods.${order.room_service_location?.type}`);
    }

    if (order.method === ORDER_METHODS.delivery && order.delivery_provider !== DeliveryProvider.Standalone) {
      return t(`order_methods.${ORDER_METHODS.pickup}`);
    }

    return t(`order_methods.${order.method}`);
  }, [order.method]);

  const props = {
    order,
    preparationTime,
    setPreparationTime,
    pickupTime,
    minutesFromNow,
    orderMethod,
    isOrderAcceptable,
    isToday,
    loading,
    printing,
    acceptOrder: _acceptOrder,
    toggleModal,
  };

  return (
    <CModalFooter>
      {(company?.delivery_mode === DeliveryMode.External || isExternalDelivery) && !order?.scheduled_for ? (
        <MultiStepOrderConfirmation {...props} />
      ) : (
        <SingleStepOrderConfirmation {...props} />
      )}
    </CModalFooter>
  );
}
