import Pusher from 'pusher-js';
import AuthorizationTokenStorage from '../auth/AuthorizationTokenStorage';
import { PUSHER_CONNECTION_STATES } from '../exports/Constants';

class PusherHelper {
  static connect = async () => {
    const config = this.getConfig();

    return new Pusher(config.key, config);
  };

  static isConnected = (client) =>
    client &&
    client?.connection &&
    (client.connection.state === PUSHER_CONNECTION_STATES.CONNECTING ||
      client.connection.state === PUSHER_CONNECTION_STATES.CONNECTED);

  static getConfig = () => ({
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    encrypted: true,
    authEndpoint: `${import.meta.env.VITE_API_BASE_URL}/api/broadcasting/auth`,
    auth: {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${AuthorizationTokenStorage.getToken()}`,
      },
    },
  });
}
export default PusherHelper;
