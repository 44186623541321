import { CCol, CRow } from '@coreui/react';
import React from 'react';
import { collect } from 'collect.js';
import tw from 'twin.macro';
import styled from 'styled-components';
import Variant from './Variant';
import Modifier from './Modifier';
import useHelpers from '../../hooks/useHelpers';

export default function OrderLineItem({ lineItem, isLastItem }) {
  const { formatCurrency, formatProductName } = useHelpers();

  const { modifier_groups, children } = lineItem;
  const variantModifierGroup = collect(modifier_groups).first((item) => item.is_variant === true);
  const variant = variantModifierGroup
    ? collect(lineItem.modifiers).first((item) => item.modifier_group_id === variantModifierGroup.id)
    : null;
  const freeModifiers = collect(lineItem.modifiers).filter(
    (item) => item.original_price === 0 && item.modifier_group_id !== variantModifierGroup?.id,
  );
  const paidModifiers = collect(lineItem.modifiers).filter(
    (item) => item.original_price !== 0 && item.modifier_group_id !== variantModifierGroup?.id,
  );

  return (
    <>
      <CRow className="mt-3">
        <CCol md={1} sm={1} xs={1}>
          <div className="website-text bold">{lineItem.quantity}x</div>
        </CCol>
        <CCol md={9} sm={8} xs={8}>
          <div className="website-text bold break-word">{formatProductName(lineItem)}</div>
        </CCol>
        <CCol md={2} sm={3} xs={3}>
          <div className="text-right website-text">{formatCurrency(lineItem.product_original_price)}</div>
        </CCol>
      </CRow>
      {children?.length > 0 && (
        <CRow className="mt-1">
          <CCol xs={1} />
          <CCol xs={11}>
            <ul className="pl-3">
              {children.map((item) => (
                <li key={`line-item-${lineItem.id}-child-${item.id}`}>{formatProductName(item)}</li>
              ))}
            </ul>
          </CCol>
        </CRow>
      )}
      {variant && <Variant name={variant.name} price={variant.original_price * lineItem.quantity} />}
      {freeModifiers.isNotEmpty() && (
        <CRow className="mt-1">
          <CCol xs={1} />
          <CCol xs={11}>
            <div className="website-text">{freeModifiers.implode('name', ' · ')}</div>
          </CCol>
        </CRow>
      )}
      {paidModifiers.map((item, idx) => (
        <Modifier name={item.name} price={item.original_price * lineItem.quantity} key={idx} />
      ))}
      {lineItem.note && (
        <CRow className="mt-1">
          <CCol xs={1} />
          <CCol xs={11}>
            <div className="website-text italic">{lineItem.note}</div>
          </CCol>
        </CRow>
      )}
      {!isLastItem && <Divider />}
    </>
  );
}

const Divider = styled.hr`
  ${tw`w-full mt-2 mb-0 bg-silver`}
  height: 1px;
`;
