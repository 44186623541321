import i18next from 'i18next';
import BackendApiClient from './BackendApiClient';
import { FULFILLMENT_STATUSES } from '../exports/Constants';
import HelperMethods from '../exports/HelperMethods';

class OrderFulfillmentsManager {
  update = (id, data) =>
    BackendApiClient.requestAuthorized({
      method: 'PUT',
      url: `/staff/fulfillments/${id}`,
      data,
    });

  updatePreparationTime = (
    id,
    data,
    requireConfirmation = true,
    messageData = {},
    onSuccess = null,
    onError = null,
    onCancel = null,
  ) => {
    if (requireConfirmation) {
      return HelperMethods.displayConfirmationModal({
        show: true,
        description: i18next.t(
          'Services:Api:OrderFulfillmentsManager.update_preparation_time_confirmation_modal.description',
          messageData,
        ),
        confirmationButtonText: i18next.t(
          'Services:Api:OrderFulfillmentsManager.update_preparation_time_confirmation_modal.confirmation_button_text',
          messageData,
        ),
        cancelButtonText: i18next.t(
          'Services:Api:OrderFulfillmentsManager.update_preparation_time_confirmation_modal.cancel_button_text',
          messageData,
        ),
        onConfirm: async () => {
          const { success, data: finalData } = await this.updatePreparationTime(id, data, false);

          if (!success) {
            HelperMethods.hideConfirmationModal();
            return onError && onError(finalData);
          }

          HelperMethods.hideConfirmationModal();
          return onSuccess && onSuccess(finalData);
        },
        onCancel: () => {
          HelperMethods.hideConfirmationModal();

          return onCancel && onCancel();
        },
      });
    }

    return this.update(id, data);
  };

  markReviewed = (id) =>
    this.update(id, {
      status: FULFILLMENT_STATUSES.reviewed,
    });
}

export default new OrderFulfillmentsManager();
