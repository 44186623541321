import { collect } from 'collect.js';
import ReduxHooks from '../../store/ReduxHooks';
import { actionCreators } from '../../store/actions';
import { EventHelperTypes } from './Constants';

class HelperMethods {
  triggerEventSideEffects = (eventAction) => {
    ReduxHooks.dispatch(actionCreators.eventHelper.setEvent(eventAction));
  };

  resetEventSideEffects = () => {
    ReduxHooks.dispatch(actionCreators.eventHelper.resetEvent());
  };

  formatCurrency(amount, currency = 'EUR', locale = null) {
    return new Intl.NumberFormat(locale ?? this.guessLocaleFromCurrency(currency), {
      style: 'currency',
      currency,
    }).format(amount);
  }

  guessLocaleFromCurrency(currency) {
    return (
      {
        EUR: 'de-DE',
        USD: 'en-US',
        GBP: 'en-UK',
      }[currency] ?? 'de-DE'
    );
  }

  min(...values) {
    return collect(values)
      .filter((item) => !!item)
      .min();
  }

  max(...values) {
    return collect(values)
      .filter((item) => !!item)
      .max();
  }

  triggerLogoutEvent = () => {
    this.triggerEventSideEffects({
      type: EventHelperTypes.LOG_OUT,
    });
  };

  displayConfirmationModal(payload) {
    this.triggerEventSideEffects({
      type: EventHelperTypes.DISPLAY_CONFIRMATION_MODAL,
      payload,
    });
  }

  hideConfirmationModal() {
    this.triggerEventSideEffects({
      type: EventHelperTypes.DISPLAY_CONFIRMATION_MODAL,
      payload: null,
    });
  }
}

export default new HelperMethods();
