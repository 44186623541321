import { DeliveryProvider, ORDER_METHODS } from '../../../../../../../services/exports/Constants';

export default {
  header: 'Dauert’s doch was länger/ kürzer?',
  text: {
    [ORDER_METHODS.pickup]:
      'Erwartete Abholzeit: <strong>{{date}}</strong> um <strong>{{time}}</strong><br/> Bei Bedarf können Sie die Zeit hier ändern.',
    [ORDER_METHODS.room_service]:
      'Erwartete Abholzeit: <strong>{{date}}</strong> um <strong>{{time}}</strong><br/> Bei Bedarf können Sie die Zeit hier ändern.',
    [ORDER_METHODS.delivery]: {
      [DeliveryProvider.Standalone]:
        'Erwartete Lieferzeit: <strong>{{date}}</strong> um <strong>{{time}}</strong><br/> Bei Bedarf können Sie die Zeit hier ändern.',
      [DeliveryProvider.FirstDelivery]: 'Der Fahrer wird die Bestellung um <strong>{{time}}</strong> abholen',
    },
  },
  labels: {
    pickup_time: 'Abholzeit',
    delivery_time: 'Lieferzeit',
    today: 'Heute',
  },
  buttons: {
    confirm_pickup: 'Neue Abholzeit Bestätigen',
    confirm_delivery: 'Neue Lieferzeit bestätigen',
  },
};
