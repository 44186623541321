import * as React from 'react';

function CreditCardIcon({ width, height, className, style }) {
  const _width = width ?? 28;
  const _height = height ?? _width * 0.71;

  return (
    <svg
      width={_width}
      height={_height}
      viewBox={`0 0 ${_width} ${_height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M28 4.91406V3.4375C28 1.74612 26.6289 0.375 24.9375 0.375H3.0625C1.37112 0.375 0 1.74612 0 3.4375V4.91406C0 5.06505 0.122445 5.1875 0.273438 5.1875H27.7266C27.8776 5.1875 28 5.06505 28 4.91406Z"
        fill="#5F6974"
      />
      <path
        d="M0 7.21094V16.5625C0 18.2539 1.37112 19.625 3.0625 19.625H24.9375C26.6289 19.625 28 18.2539 28 16.5625V7.21094C28 7.05995 27.8776 6.9375 27.7266 6.9375H0.273438C0.122445 6.9375 0 7.05995 0 7.21094ZM7 14.375C7 14.8582 6.60822 15.25 6.125 15.25H5.25C4.76678 15.25 4.375 14.8582 4.375 14.375V13.5C4.375 13.0168 4.76678 12.625 5.25 12.625H6.125C6.60822 12.625 7 13.0168 7 13.5V14.375Z"
        fill="#5F6974"
      />
    </svg>
  );
}

export default CreditCardIcon;
