import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import classnames from 'classnames';

export default function Banner({
  show,
  title,
  text,

  type = 'primary' | 'notice' | 'warning',
  delay,
  leftAction,
  rightAction,
  className,
}) {
  const [display, setDisplay] = useState(show);

  useEffect(() => {
    if (delay) {
      setTimeout(() => setDisplay(false), delay);
    }
  }, [delay]);

  function renderPrimary() {
    return <PrimaryBanner className={className}>{renderContent()}</PrimaryBanner>;
  }

  function renderNotice() {
    return <NoticeBanner className={className}>{renderContent()}</NoticeBanner>;
  }

  function renderWarning() {
    return <WarningBanner className={className}>{renderContent()}</WarningBanner>;
  }

  function renderContent() {
    return (
      <>
        {leftAction && (
          <LeftAction
            className={classnames('col-span-3', {
              'col-span-4': !rightAction,
            })}
          >
            {leftAction}
          </LeftAction>
        )}
        <TextContainer
          className={classnames('website-text white', {
            'col-span-6 text-center': leftAction && rightAction,
            'col-span-8 text-left': !leftAction || !rightAction,
            'col-span-12 text-center': !leftAction && !rightAction,
          })}
        >
          <Title>{title}</Title>
          {text && <Text className="website-text-mini text-white mt-1">{text}</Text>}
        </TextContainer>
        {rightAction && (
          <RightAction
            className={classnames('col-span-3', {
              'col-span-4': !leftAction,
            })}
          >
            {rightAction}
          </RightAction>
        )}
      </>
    );
  }

  function render() {
    switch (type) {
      case 'primary':
        return renderPrimary();
      case 'warning':
        return renderWarning();
      case 'notice':
        return renderNotice();
      default:
        return renderPrimary();
    }
  }

  return display && render();
}

const LeftAction = tw.div`flex flex-col justify-start my-auto text-left`;
const TextContainer = tw.div`flex flex-col justify-center text-center font-semibold`;
const Title = tw.div`font-semibold`;
const Text = tw.div`font-normal`;
const RightAction = tw.div`flex flex-col justify-end my-auto text-right`;
const PrimaryBanner = styled.div`
  ${tw`h-16 px-4 -mx-2 bg-green top-0 z-1050 flex grid grid-cols-12 gap-2`}
  width: 120%;
`;

const WarningBanner = styled.div`
  ${tw`h-16 px-4 -mx-2 bg-red top-0 z-1050 flex grid grid-cols-12 gap-2`}
  width: 120%;
`;

const NoticeBanner = styled.div`
  ${tw`h-16 px-4 -mx-2 bg-orange top-0 z-1050 flex grid grid-cols-12 gap-2`}
  width: 120%;
`;
