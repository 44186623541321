import detectBrowserLanguage from 'detect-browser-language';
import { AVAILABLE_LANGUAGES } from '../exports/Constants';
import ConfigManager from '../api/ConfigManager';
import WebViewCommunicatorResource from '../resources/WebViewCommunicatorResource';

export const LANGUAGE_KEY = 'LANGUAGE';
export const LANGUAGE_PARAM = 'lng';

class LocalizationManager {
  getLanguage() {
    return localStorage.getItem(LANGUAGE_KEY);
  }

  saveLanguage(language) {
    localStorage.setItem(LANGUAGE_KEY, language);
    ConfigManager.update({
      lang: language,
    });
    WebViewCommunicatorResource.forwardLanguage({
      lang: language,
    });
  }

  getLanguageFromUrl() {
    const url = window.location.href;
    const query = `${LANGUAGE_PARAM}?=`;

    if (url?.includes(query)) {
      return url?.split(query)[1];
    }

    return null;
  }

  getPreferredLanguage() {
    const preferredBrowserLanguage = detectBrowserLanguage();
    const language = preferredBrowserLanguage?.split(/-|_/)[0];
    const defaultLanguage = AVAILABLE_LANGUAGES?.find((item) => item?.language === language)?.language;

    if (!defaultLanguage) {
      return null;
    }

    return defaultLanguage;
  }
}

export default new LocalizationManager();
