import { batch } from 'react-redux';
import { store } from './index';

class ReduxHooks {
  /**
   * Dispatch an action to redux reducers so that they update the state
   * @dispatch dispatch
   */
  dispatch = (action) => {
    store.dispatch(action);
  };

  /**
   * Return the current state of the store
   * @dispatch getStore
   */
  getStore = () => store.getState();

  /**
   * Clear redux store
   * @method clearReduxStore
   */
  clearReduxStore = () => {
    store.dispatch({ type: 'RESET_STORE' });
  };

  /**
   * Dispatch multiple actions and cause a single rerender
   * @method batchActions
   */
  batchActions = (actionArray) =>
    batch(() => {
      actionArray.forEach((action) => {
        store.dispatch(action);
      });
    });
}
export default new ReduxHooks();
