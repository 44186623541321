import { createSlice } from '@reduxjs/toolkit';
import collect from 'collect.js';
import moment from 'moment-timezone';
import { FULFILLMENT_STATUSES } from '../../services/exports/Constants';

export default createSlice({
  name: 'orders',
  initialState: {
    orders: [],
    newOrders: [],
    unreviewedOrders: [],
    scheduledOrders: [],
    ongoingOrders: [],
  },
  reducers: {
    setOrders: (state, action) => {
      try {
        state.orders = action.payload;
      } catch (error) {
        console.error('Failed to set orders', error);
      }
    },
    setNewOrders: (state) => {
      try {
        state.newOrders = collect(state.orders).where('fulfillment_status', FULFILLMENT_STATUSES.reviewed).toArray();
      } catch (error) {
        console.error('Failed to set new orders', error);
      }
    },
    setUnreviewedOrders: (state) => {
      try {
        state.unreviewedOrders = collect(state.orders)
          .where('is_manually_confirmed', false)
          .where('fulfillment_status', '!=', FULFILLMENT_STATUSES.reviewed)
          .reverse()
          .toArray();
      } catch (error) {
        console.error('Failed to set unreviewed orders', error);
      }
    },
    setScheduledOrders: (state) => {
      try {
        state.scheduledOrders = collect(state.orders)
          .filter((item) => item.fulfillment_status === FULFILLMENT_STATUSES.accepted && item.scheduled_for !== null)
          .sort((a, b) => moment(a.estimated_pickup_time).unix() - moment(b.estimated_pickup_time).unix())
          .toArray();
      } catch (error) {
        console.error('Failed to set scheduled orders', error);
      }
    },
    setOngoingOrders: (state) => {
      try {
        state.ongoingOrders = collect(state.orders)
          .filter(
            (item) =>
              (item.fulfillment_status === FULFILLMENT_STATUSES.accepted && item.scheduled_for === null) ||
              item.fulfillment_status === FULFILLMENT_STATUSES.preparing ||
              item.fulfillment_status === FULFILLMENT_STATUSES.ready ||
              item.fulfillment_status === FULFILLMENT_STATUSES.on_delivery ||
              item.fulfillment_status === FULFILLMENT_STATUSES.rejected,
          )
          .toArray();
      } catch (error) {
        console.error('Failed to set ongoing orders', error);
      }
    },
  },
});

export const props = (state) => state;
