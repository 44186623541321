import React from 'react';
import Lottie from 'react-lottie-player';
import classnames from 'classnames';
import LoadingIndicatorLight from '../../resources/assets/animations/LoadingIndicatorLight.json';
import LoadingIndicatorPrimary from '../../resources/assets/animations/LoadingIndicatorPrimary.json';

export const Types = {
  primary: 'primary',
  secondary: 'secondary',
  transparent: 'transparent',
  info: 'info',
  warning: 'warning',
  danger: 'danger',
  custom: 'custom',
};

export const Variants = {
  fill: 'fill',
  outline: 'outline',
};

export default function Button({
  ref,
  title,
  onClick,
  className,
  style,
  type = Types.primary,
  variant = Variants.fill,
  color = null,
  textColor = null,
  loading = false,
  disabled = false,
}) {
  function renderLightLoadingIndicator() {
    return (
      <Lottie
        play
        loop
        animationData={type === 'primary' ? LoadingIndicatorLight : LoadingIndicatorPrimary}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
        style={{
          margin: 'auto',
          height: '25px',
          width: '50px',
        }}
      />
    );
  }

  const _style = {
    backgroundColor: type === Types.custom && variant === Variants.fill && color && !disabled ? color : undefined,
    borderColor: type === Types.custom && variant === Variants.outline && color && !disabled ? color : undefined,
    color: type === Types.custom && textColor && !disabled ? textColor : undefined,
    ...style,
  };

  const _color = disabled
    ? 'gray-300'
    : ({
        [Types.primary]: 'brand-primary',
        [Types.secondary]: 'gray-100',
        [Types.transparent]: 'transparent',
        [Types.info]: 'black',
        [Types.warning]: 'brand-danger/30',
        [Types.danger]: 'brand-danger',
      }[type] ?? 'brand-primary');

  const _textColor = disabled
    ? 'white'
    : ({
        [Types.primary]: 'white',
        [Types.secondary]: 'text-default',
        [Types.transparent]: 'text-default',
        [Types.info]: 'white',
        [Types.warning]: 'white',
        [Types.danger]: 'white',
        [Types.custom]: textColor ?? 'white',
      }[type] ?? 'text-default');

  const generalClasses = {
    'tw-rounded': true,
    [`tw-bg-${_color} tw-text-${_textColor} tw-shadow tw-border-none`]: variant === Variants.fill,
    [`tw-bg-white tw-text-${_color} tw-border tw-border-solid tw-border-${_color}`]: variant === Variants.outline,
  };

  const classMapping =
    {
      [Types.primary]: {
        'tw-bg-gradient-to-r tw-from-gradient1-100 tw-to-gradient1-200': variant === Variants.fill,
      },
      [Types.secondary]: {
        '!tw-shadow-none': variant === Variants.fill,
      },
      [Types.transparent]: {
        '!tw-bg-transparent !tw-border-black !tw-text-black': variant === Variants.outline,
      },
      [Types.warning]: {
        '!tw-text-brand-danger': variant === Variants.fill,
      },
    }[type] ?? {};

  return (
    <button
      ref={ref}
      onClick={onClick}
      className={classnames(
        'tw-px-small tw-py-xsmall tw-justify-center tw-items-center tw-text-base tw-sm:text-base sm:px-4 tw-xs:px-2',
        generalClasses,
        classMapping,
        className,
      )}
      style={_style}
      disabled={disabled || loading}
    >
      {!loading ? title : renderLightLoadingIndicator()}
    </button>
  );
}
