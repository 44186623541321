import React from 'react';
import ScheduledOrdersFlicker from './ScheduledOrdersFlicker';
import AsapOrdersFlicker from './AsapOrdersFlicker';

export default function NewOrdersFlicker({
  ordersCount,
  onClick,

  type = 'default' | 'scheduled',
}) {
  function renderFlicker() {
    switch (type) {
      case 'scheduled':
        return <ScheduledOrdersFlicker ordersCount={ordersCount} onClick={onClick} />;
      default:
        return <AsapOrdersFlicker ordersCount={ordersCount} onClick={onClick} />;
    }
  }

  return renderFlicker();
}
