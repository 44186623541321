import BackendApiClient from './BackendApiClient';

class ConfigManager {
  update = (data) =>
    BackendApiClient.requestAuthorized({
      method: 'PUT',
      url: '/staff/config',
      data,
    });
}

export default new ConfigManager();
