import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { EventHelperTypes } from '../services/exports/Constants';
import HelperMethods from '../services/exports/HelperMethods';
import ConfirmationModal from '../components/modals/ConfirmationModal';
import useAuth from '../hooks/useAuth';

export default function SideEffectsProvider({ children }) {
  const { event } = useSelector((state) => state.eventHelper);

  const { logOut } = useAuth();

  const [confirmationModal, setConfirmationModal] = useState(false);

  useEffect(() => {
    handleReduxSideEffects();
  }, [event]);

  async function handleReduxSideEffects() {
    try {
      const { type, payload } = event;

      if (!type) {
        return;
      }

      return new Promise((resolve) => {
        switch (type) {
          case EventHelperTypes.LOG_OUT:
            logOut();
            break;
          case EventHelperTypes.DISPLAY_CONFIRMATION_MODAL:
            setConfirmationModal(payload);
            break;
        }

        resolve(true);
      }).then(HelperMethods.resetEventSideEffects);
    } catch (error) {
      console.error(`Failed to handle redux side effect: ${error}`);
    }
  }

  const renderConfirmationModal = useMemo(
    () =>
      confirmationModal?.show && (
        <ConfirmationModal
          show={confirmationModal?.show}
          title={confirmationModal?.description}
          confirmationButtonText={confirmationModal?.confirmationButtonText}
          cancelButtonText={confirmationModal?.cancelButtonText}
          onConfirm={confirmationModal?.onConfirm}
          onCancel={confirmationModal?.onCancel}
          confirming={confirmationModal?.confirming}
          cancelling={confirmationModal?.cancelling}
        />
      ),
    [confirmationModal],
  );

  return (
    <>
      {children}
      {renderConfirmationModal}
    </>
  );
}
