import React from 'react';
import classnames from 'classnames';
import theme from '../../resources/theme';

export default function PrimaryOrderBadge({ order, className, children }) {
  const color = !order.is_pickup_time_optimized ? theme.colors.purple : null;

  const _style = {
    backgroundColor: color ?? undefined,
  };

  return (
    <div
      className={classnames('tw-bg-brand-primary rounded-lg tw-py-mini tw-px-small tw-w-fit', className)}
      style={_style}
    >
      <h6 className="text-white">{children}</h6>
    </div>
  );
}
