import React from 'react';
import { DeliveryProvider } from '../../services/exports/Constants';
import UberEatsIcon from '../../resources/assets/icons/UberEatsIcon';
import WoltIcon from '../../resources/assets/icons/WoltIcon';

export default function DeliveryProviderIcon({ provider, ...props }) {
  return (
    {
      [DeliveryProvider.FirstDelivery]: <UberEatsIcon {...props} />,
      [DeliveryProvider.WoltDrive]: <WoltIcon {...props} />,
    }[provider] ?? null
  );
}
