import React from 'react';

export default function WoltIcon({ className, width, color }) {
  const extra = width ? { width, height: width } : {};

  return (
    <svg
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? 'h-10 w-10'}
      {...extra}
    >
      <rect width="500" height="500" fill={color ?? '#009DE4'} />
      <path
        d="M261.976 223.387C280.448 221.285 288.978 223.638 295.976 249.387C297.505 255.014 319.339 234.682 315.976 241.986C311.188 252.387 318.384 197.785 326.476 189.887C326.476 189.887 334.723 189.192 343.726 189.387C352.729 189.582 346.976 210.889 346.976 210.889C344.524 224.977 316.644 296.067 336.976 294.887C370.225 292.957 363.976 217.889 369.976 239.387C371.285 244.079 357.312 245.535 353.976 241.986C350.639 238.438 351.211 232.173 354.476 228.389C357.92 224.397 382.786 227.784 379.476 231.887C365.359 249.387 367.753 207.972 376.476 204.387C384.492 201.092 392.506 202.945 394.976 206.887C399.568 214.216 387.476 249.889 379.476 238.387C372.872 228.893 402.976 225.389 416.476 229.387C420.445 230.563 418.499 240.013 414.476 240.986C402.476 243.889 393.976 241.387 390.976 242.387C387.976 243.387 377.976 286.387 388.476 294.887C398.333 302.866 405.976 283.142 409.476 282.387C410.458 282.175 413.476 282.387 415.976 285.387C420.442 290.746 396.812 315.92 377.476 311.489C357.98 307.022 362.855 288.487 361.855 290.887C359.355 296.887 338.827 318.192 319.976 310.489C296.476 300.887 310.476 255.887 312.976 255.887C318.563 255.887 288.976 273.387 293.726 263.413C285.818 264.184 254.229 290.268 251.976 297.887C261.976 296.387 270.976 278.387 273.476 264.887C274.438 259.694 280.476 268.387 280.476 268.387C280.476 268.387 272.476 265.387 268.476 254.887C264.476 244.387 268.476 236.887 268.476 236.887C253.476 233.387 245.547 254.323 244.476 263.413C242.476 280.387 239.976 287.387 251.976 297.887C254.229 290.268 285.818 264.184 293.726 263.413C294.408 286.247 276.502 308.945 252.976 310.489C218.098 312.779 213.116 285.562 219.476 264.387C227.25 238.504 235.098 226.446 261.976 223.387Z"
        fill="white"
      />
      <path
        d="M86.9762 210.889C91.9762 191.889 110.476 195.389 113.976 196.889C117.476 198.389 108.976 222.889 107.476 244.389C105.976 265.889 104.476 302.389 108.976 290.889C110.878 286.027 125.976 252.889 125.976 252.889C125.976 252.889 143.476 211.889 147.476 207.389C151.476 202.889 162.976 202.389 165.976 207.389C168.976 212.389 159.476 296.389 177.476 291.889C195.476 287.388 192.976 191.889 195.976 189.389C198.976 186.889 217.476 187.889 220.976 191.388C224.476 194.888 217.476 304.888 171.476 309.889C142.111 313.081 143.476 256.889 143.476 254.889C143.476 252.889 122.339 302.271 113.976 306.389C105.613 310.507 94.9759 310.389 85.976 303.389C76.9761 296.388 81.9762 229.889 86.9762 210.889Z"
        fill="white"
      />
    </svg>
  );
}
