import * as React from 'react';
import classnames from 'classnames';

export default function InfoIcon({ className, color }) {
  return (
    <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={classnames('h-4', className)}>
      <path
        d="M7.7 12H9.3V7.2H7.7V12ZM8.5 5.6C8.72667 5.6 8.91667 5.52333 9.07 5.37C9.22333 5.21667 9.3 5.02667 9.3 4.8C9.3 4.57333 9.22333 4.38333 9.07 4.23C8.91667 4.07667 8.72667 4 8.5 4C8.27333 4 8.08333 4.07667 7.93 4.23C7.77667 4.38333 7.7 4.57333 7.7 4.8C7.7 5.02667 7.77667 5.21667 7.93 5.37C8.08333 5.52333 8.27333 5.6 8.5 5.6ZM8.5 16C7.39333 16 6.35333 15.79 5.38 15.37C4.40667 14.95 3.56 14.38 2.84 13.66C2.12 12.94 1.55 12.0933 1.13 11.12C0.71 10.1467 0.5 9.10667 0.5 8C0.5 6.89333 0.71 5.85333 1.13 4.88C1.55 3.90667 2.12 3.06 2.84 2.34C3.56 1.62 4.40667 1.05 5.38 0.63C6.35333 0.21 7.39333 0 8.5 0C9.60667 0 10.6467 0.21 11.62 0.63C12.5933 1.05 13.44 1.62 14.16 2.34C14.88 3.06 15.45 3.90667 15.87 4.88C16.29 5.85333 16.5 6.89333 16.5 8C16.5 9.10667 16.29 10.1467 15.87 11.12C15.45 12.0933 14.88 12.94 14.16 13.66C13.44 14.38 12.5933 14.95 11.62 15.37C10.6467 15.79 9.60667 16 8.5 16ZM8.5 14.4C10.2867 14.4 11.8 13.78 13.04 12.54C14.28 11.3 14.9 9.78667 14.9 8C14.9 6.21333 14.28 4.7 13.04 3.46C11.8 2.22 10.2867 1.6 8.5 1.6C6.71333 1.6 5.2 2.22 3.96 3.46C2.72 4.7 2.1 6.21333 2.1 8C2.1 9.78667 2.72 11.3 3.96 12.54C5.2 13.78 6.71333 14.4 8.5 14.4Z"
        fill={color ?? '#161616'}
      />
    </svg>
  );
}
