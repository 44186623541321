import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Banner from './Banner';
import Button, { Types, Variants } from '../buttons/Button';
import { ORDERS_PAUSE_REASONS } from '../../services/exports/Constants';
import ProfileManager from '../../services/api/ProfileManager';
import ReduxHooks from '../../store/ReduxHooks';
import { actionCreators } from '../../store/actions';
import useHelpers from '../../hooks/useHelpers';

export default function OrdersPausedManuallyBanner() {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Banners:OrdersPausedManuallyBanner' });

  const { company } = useSelector((state) => state.company);

  const { formatTime } = useHelpers();

  const [loading, setLoading] = useState(false);

  function ordersPausedManually() {
    return (
      company?.orders_paused_from !== null &&
      moment(company?.orders_paused_from).isBefore(moment()) &&
      company?.orders_paused_until !== null &&
      moment(company?.orders_paused_until).isAfter(moment()) &&
      company?.orders_pause_reason === ORDERS_PAUSE_REASONS.manual
    );
  }

  const unpauseOrders = async () => {
    setLoading(true);
    const { success, data } = await ProfileManager.unpauseOrders();
    setLoading(false);

    if (success) {
      ReduxHooks.dispatch(actionCreators.company.set(data.data));
    }
  };

  return (
    ordersPausedManually() && (
      <Banner
        type="warning"
        show
        className="w-screen"
        title={
          <div className="d-flex">
            <span className="my-auto">
              {t('title', {
                time: moment(company?.orders_paused_until).isSame(moment().endOf('day'))
                  ? t('labels.rest_of_day')
                  : formatTime(company?.orders_paused_until),
              })}
            </span>
          </div>
        }
        rightAction={
          <Button
            title={t('button')}
            type={Types.danger}
            variant={Variants.outline}
            loading={loading}
            onClick={unpauseOrders}
          />
        }
      />
    )
  );
}
