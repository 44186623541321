import moment from 'moment-timezone';
import React from 'react';
import classnames from 'classnames';

export default function DeliveryTimeDifferenceBadge({ initial, actual, className }) {
  if (initial === actual) {
    return null;
  }

  const diff = moment(actual).set('second', 0).diff(moment(initial).set('second', 0), 'minutes');
  const success = diff < 0;

  return (
    <span
      className={classnames(
        'tw-block tw-text-center tw-border-solid tw-rounded-md tw-text-xs tw-px-tiny',
        {
          'tw-border-brand-primary tw-bg-brand-primary/25 tw-text-brand-primary': success,
          'tw-border-brand-text-warning tw-bg-brand-inkDanger-danger_1 tw-text-brand-text-warning': !success,
        },
        className,
      )}
    >
      {success ? '-' : '+'}
      {Math.abs(diff)}
      {' min'}
    </span>
  );
}
