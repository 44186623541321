import React from 'react';

export default function CashIcon({ className, style }) {
  return (
    <svg className={className ?? 'h-4 w-4'} viewBox="0 0 19 14" fill="none" style={style}>
      <path
        d="M10.7526 8.2526C10.0582 8.2526 9.46788 8.00955 8.98177 7.52344C8.49566 7.03733 8.2526 6.44705 8.2526 5.7526C8.2526 5.05816 8.49566 4.46788 8.98177 3.98177C9.46788 3.49566 10.0582 3.2526 10.7526 3.2526C11.447 3.2526 12.0373 3.49566 12.5234 3.98177C13.0095 4.46788 13.2526 5.05816 13.2526 5.7526C13.2526 6.44705 13.0095 7.03733 12.5234 7.52344C12.0373 8.00955 11.447 8.2526 10.7526 8.2526ZM4.08594 11.1693C3.73872 11.1693 3.44358 11.0477 3.20052 10.8047C2.95747 10.5616 2.83594 10.2665 2.83594 9.91927V1.58594C2.83594 1.23872 2.95747 0.943576 3.20052 0.700521C3.44358 0.457465 3.73872 0.335938 4.08594 0.335938H17.4193C17.7665 0.335938 18.0616 0.457465 18.3047 0.700521C18.5477 0.943576 18.6693 1.23872 18.6693 1.58594V9.91927C18.6693 10.2665 18.5477 10.5616 18.3047 10.8047C18.0616 11.0477 17.7665 11.1693 17.4193 11.1693H4.08594ZM6.16927 9.91927H15.3359C15.3359 9.33594 15.5373 8.84288 15.9401 8.4401C16.3429 8.03733 16.8359 7.83594 17.4193 7.83594V3.66927C16.8359 3.66927 16.3429 3.46788 15.9401 3.0651C15.5373 2.66233 15.3359 2.16927 15.3359 1.58594H6.16927C6.16927 2.16927 5.96788 2.66233 5.5651 3.0651C5.16233 3.46788 4.66927 3.66927 4.08594 3.66927V7.83594C4.66927 7.83594 5.16233 8.03733 5.5651 8.4401C5.96788 8.84288 6.16927 9.33594 6.16927 9.91927ZM16.1693 13.6693H1.58594C1.23872 13.6693 0.943576 13.5477 0.700521 13.3047C0.457465 13.0616 0.335938 12.7665 0.335938 12.4193V2.83594H1.58594V12.4193H16.1693V13.6693ZM4.08594 9.91927V1.58594V9.91927Z"
        fill="#3E734C"
      />
    </svg>
  );
}
