import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PusherContext } from '../contexts/PusherContext';
import PusherHelper from '../services/pusher/PusherHelper';
import { PUSHER_CONNECTION_STATES, PUSHER_EVENT_TYPES } from '../services/exports/Constants';
import useAuth from '../hooks/useAuth';
import { systemChannel } from '../services/pusher/Channels';

export default function PusherProvider({ children }) {
  const { isLoggedIn } = useAuth();

  const [pusherClient, setPusherClient] = useState(null);

  const pusherClientRef = useRef(pusherClient);
  pusherClientRef.current = pusherClient;

  useEffect(() => {
    isLoggedIn && connect();

    return () => disconnect();
  }, [isLoggedIn]);

  const connect = async () => {
    if (!PusherHelper.isConnected(pusherClientRef.current)) {
      const client = await PusherHelper.connect();
      setPusherClient(client);

      client.subscribe(systemChannel()).bind(PUSHER_EVENT_TYPES.FORCE_REFRESH, () => window.location.reload());

      return client.connection.bind('state_change', (states) => {
        const { previous, current } = states;

        if (previous === PUSHER_CONNECTION_STATES.UNAVAILABLE && current === PUSHER_CONNECTION_STATES.CONNECTING) {
          window.location.reload();
        }
      });
    }

    return pusherClientRef.current;
  };

  const disconnect = () => {
    pusherClientRef.current?.unbind_all();
    pusherClientRef.current?.disconnect();
    pusherClientRef.current?.unsubscribe(systemChannel());
    setPusherClient(null);
  };

  const unbind = (eventsToUnbind, channel = null) => {
    const events = typeof eventsToUnbind === 'string' ? [eventsToUnbind] : eventsToUnbind;

    if (channel) {
      events.forEach((event) => pusherClientRef.current?.channel(channel)?.unbind(event));
    }

    events.forEach((event) => pusherClientRef.current?.unbind(event));
  };

  const context = useMemo(
    () => ({
      client: pusherClientRef.current,
      isConnected: PusherHelper.isConnected(pusherClientRef.current),
      connect,
      disconnect,
      unbind,
      unsubscribe: (channel, eventsToUnbind) => {
        pusherClientRef.current?.unsubscribe(channel);
        unbind(eventsToUnbind, channel);
      },
    }),
    [pusherClientRef.current],
  );

  return <PusherContext.Provider value={context}>{children}</PusherContext.Provider>;
}
