import React from 'react';
import tw from 'twin.macro';
import Button, { Types } from '../buttons/Button';

export default function ConfirmationModal({
  show,
  title,
  description,
  confirmationButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  confirming,
  cancelling,
}) {
  return (
    show && (
      <Backdrop>
        <Container>
          <h6>{title}</h6>
          {description ? <p>{description}</p> : null}
          <div className="tw-mt-small tw-flex tw-gap-4">
            <Button title={cancelButtonText} onClick={onCancel} loading={cancelling} type={Types.transparent} />
            <Button title={confirmationButtonText} onClick={onConfirm} loading={confirming} />
          </div>
        </Container>
      </Backdrop>
    )
  );
}

const Backdrop = tw.div`fixed flex h-screen w-full top-0 left-0 bg-black bg-opacity-75 p-5 z-[99999]`;
const Container = tw.div`m-auto bg-white p-4 rounded-lg`;
